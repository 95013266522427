import React, {useContext} from 'react';
import './App.scss';
import {Form} from "./Components/Form";
import {AppContext} from "./Context/AppContext/AppContext";
import {Home} from "./Components/Home";

function App() {

    const {user, setNewUser} = useContext(AppContext);

    return (
        <div className="App">
            { !user && <Form setUser={setNewUser} /> }
            { user && <Home/>}
        </div>
    );
}

export default App;
