import {useContext, useState} from "react";
import {CodeSubmitReply, UserStruct} from "../../Helpers/DataTypes";
import './Wheel.scss';
import {Wheel as CWheel} from 'react-custom-roulette'
import {Button} from "react-bootstrap";
import {Modal} from "../Modal";
import {savePair} from "../../Helpers/DataChecker";
import {AppContext} from "../../Context/AppContext/AppContext";

export const Wheel = ({options = [], goBack}: { options?: Array<UserStruct>, goBack: () => void }) => {

    const {user, setNewUser} = useContext(AppContext);
    const RandomStart = Math.floor(Math.random() * options.length);

    const [mustSpin, setMustSpin] = useState<boolean>(false);
    const [prizeNumber, setPrizeNumber] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [winner, setWinner] = useState<UserStruct | null>(null);
    const [done, setDone] = useState<boolean>(false);

    const formatMyData = () => {
        return options.map((opt) => {
            return {option: opt.name, id: opt.id, pair: opt.pair};
        });
    }

    const customOptions = formatMyData();


    const getWinningSlice = (): number => {
        return options.findIndex((opt) => opt.id === user?.pair?.id);
    }

    const handleSpinClick = () => {
        const newPrizeNumber = getWinningSlice();
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
    };

    const onWheelStopped = () => {
        setMustSpin(false);
        if (typeof prizeNumber === 'number') {
            setWinner(options[prizeNumber]);
            toggleModal();
            setDone(true);
            saveWinner(options[prizeNumber]);
        }
    }

    const saveWinner = (u: UserStruct): boolean => {
        if (!user || !u) {
            return false;
        }
        savePair(user.id, user.code, u.id)
            .then((res: CodeSubmitReply) => {
                setNewUser({...user, verified: true})
                return res.success;
            }).catch((e: any) => {
                console.log('error >>> ', e.message)
                return false;
            });
        return false;
    }


    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <div id={"SpinningWheel"}>
            <Modal open={isOpen} onClose={toggleModal} title={"Amigo secreto sorteado"}>
                <div>
                    <p>O seu amigo secreto é:</p>
                    <h3><strong>{winner?.name}</strong></h3>
                </div>
            </Modal>
            <div className={'wheel_wrapper'}>
                <CWheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber || RandomStart}
                    data={formatMyData()}
                    outerBorderColor={"#f2f2f2"}
                    outerBorderWidth={10}
                    innerBorderColor={"#f2f2f2"}
                    radiusLineColor={"#dedede"}
                    radiusLineWidth={1}
                    fontSize={20}
                    textColors={["#ffffff"]}
                    backgroundColors={[
                        "#F22B35",
                        "#F99533",
                        "#24CA69",
                        "#514E50",
                        "#46AEFF",
                        "#9145B7"
                    ]}
                    onStopSpinning={onWheelStopped}
                />
            </div>
            <div className={'btn_wrapper'}>
                {!done && <Button disabled={mustSpin} onClick={handleSpinClick}>Rodar</Button>}
                <Button variant={'danger'} disabled={mustSpin} onClick={goBack}>Voltar</Button>
            </div>
        </div>
    );
}