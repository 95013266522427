const APP_URL = 'https://myadmin.josebarros.ovh/'
// const APP_URL = 'http://localhost:3001/'
const POST_TYPE = 'POST';
const GET_TYPE = 'GET';


const requestFromAPI = (path: string, method: string, params: object | null): any => {
    const body = method.toUpperCase() === POST_TYPE && params ? JSON.stringify(params) : null;
    const url = `${APP_URL}${path}`;
    return fetch(url, {
        method: method.toUpperCase(),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .catch(e => {
          return Promise.reject(e);
        })
        .then(response => response.json())
};

export const validateMyCode = (code: string): any => {
    return requestFromAPI('auth/validate', POST_TYPE, {'code': code})
        .then((res: any)=> {
            return res;
        });
};

export const getAllOptions = (userId: number, code: string): any => {
    return requestFromAPI(`users/lottery/${userId}/${code}`, GET_TYPE, null)
        .then((res: any) => {
            return res;
        })
};

export const getPairInfo = (userId: number): any => {
    return requestFromAPI(`users/pair/${userId}`, GET_TYPE, null)
        .then((res: any) => {
            return res;
        })
};

export const savePair = (userId: number, userCode: string, pairId: number): any => {
    return requestFromAPI(`users/pair`, POST_TYPE, {'userId': userId, 'code': userCode, 'pairId': pairId})
        .then((res: any) => {
            return res;
        })
};