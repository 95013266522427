import React, {FormEvent, useState} from "react";
import {validateMyCode} from "../../Helpers/DataChecker";
import {Alert, Button, Form as BForm, Spinner} from "react-bootstrap";
import {CodeSubmitReply, UserStruct} from "../../Helpers/DataTypes";

interface FormProps {
    setUser: (u: UserStruct | null) => void
}

export const Form = ({setUser}: FormProps) => {

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        setErrorMessage(null);
        setIsSubmitting(true);
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const userCode = '' + formData.get('code');
        validateMyCode(userCode)
            .then((codeResult: CodeSubmitReply) => {
                if (codeResult.success) {
                    if (codeResult.user) {
                        setUser(codeResult.user);
                    }
                } else {
                    setErrorMessage(codeResult.message);
                    setUser(null);

                }
                setIsSubmitting(false);
            }).catch((e: any) => {
            setErrorMessage(e.message || 'Algo de errado aconteceu, por favor tente novamente');
            setIsSubmitting(false);
        });
    }

    return (
        <div className={'main_form_wrapper'}>
            <div id={"AppInfo"}>
                <h2>Sorteio do Amigo Secreto - Familia Silva</h2>
            </div>
            <div id={"AppForm"}>
                <div className={'form_wrapper'}>
                    <div className={'text'}>
                        <h4>Para aceder introduza o seu código no campo ao lado.</h4>
                        <p>Depois de realizado o sorteio, não poderá sortear novamente</p>
                    </div>
                    <BForm onSubmit={handleSubmit} className={'actual_form'}>
                        <BForm.Group className="mb-3" controlId="formBasicEmail">
                            <BForm.Label>Código de autenticação</BForm.Label>
                            <BForm.Control type="text" name={'code'} placeholder="Código" required/>
                        </BForm.Group>
                        <BForm.Group className="mb-3" controlId="formBasicEmail">
                            {
                                !isSubmitting &&
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    Enviar
                                </Button>
                            }
                            {isSubmitting &&
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Por favor aguarde...
                                </Button>
                            }
                        </BForm.Group>
                        <div className={'alertDiv'}>
                            {errorMessage &&
                                <div className={'alert_wrapper'}>
                                    <Alert variant="danger" dismissible>
                                        <Alert.Heading>Erro a ler o código!</Alert.Heading>
                                        <p>
                                            Erro : {errorMessage}
                                            <br/>
                                            Verifique os dados e tente novamente.
                                        </p>
                                    </Alert>
                                </div>
                            }
                        </div>
                    </BForm>

                </div>
            </div>
        </div>
    );

}