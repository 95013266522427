import {useContext, useState} from "react";
import {AppContext} from "../../Context/AppContext/AppContext";
import {Button} from "react-bootstrap";
import {Wheel} from "../Wheel";
import {LotteryRequestReply, UserStruct} from "../../Helpers/DataTypes";
import {getAllOptions} from "../../Helpers/DataChecker";

export const Home = () => {

    const {user, setNewUser} = useContext(AppContext);
    const [options, setOptions] = useState<Array<UserStruct>>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [pair, setPair] = useState<boolean>(false);
    const [goWheel, setGoWheel] = useState<boolean>(false);

    const clearUser = () => {
        setNewUser(null);
    }

    const hideWheel = () => {
        setGoWheel(false);
    }

    const reqOpts = () => {
        if (user) {
            setIsSubmitting(true);
            getAllOptions(user?.id, user?.code)
                .then((codeResult: LotteryRequestReply) => {
                    if (codeResult.success) {
                        if (codeResult.lottery) {
                            setOptions(codeResult.lottery);
                            setGoWheel(true);
                        }
                    }
                    setIsSubmitting(false);
                }).catch((e: any) => {
                setIsSubmitting(false);
            });
        }
    }

    const togglePairInfo = () => {
        setPair(!pair);
    }

    return (
        <div className={'home_wrapper'}>
            {
                !isSubmitting && !goWheel && user?.verified &&
                <div className={'welcome'}>
                    <h2>Olá {user?.name}</h2>
                    <h3>Já fez o sorteio.</h3>
                    {!pair &&
                        <div className={'buttons'}>
                            <Button variant={'info'} onClick={togglePairInfo}>Mostrar</Button>
                            <Button variant={'danger'} onClick={clearUser} disabled={isSubmitting}>Sair</Button>
                        </div>
                    }
                    {pair && user.verified && !goWheel &&
                        <div className={'pair_result'}>
                            <h3>O seu amigo secreto é: <strong>{user.pair?.name}</strong></h3>
                            <div className={'buttons'}>
                                <Button variant={'info'} onClick={togglePairInfo}>Esconder</Button>
                                <Button variant={'danger'} onClick={clearUser} disabled={isSubmitting}>Sair</Button>
                            </div>
                        </div>
                    }
                </div>
            }
            {
                !isSubmitting && !goWheel && user && !user.verified &&
                <div className={'user_start'}>
                    <h2>Olá {user?.name}</h2>
                    <div className={'buttons'}>
                        <Button variant={'primary'} onClick={reqOpts} disabled={isSubmitting}>Sortear</Button>
                        <Button variant={'danger'} onClick={clearUser} disabled={isSubmitting}>Sair</Button>
                    </div>
                </div>

            }
            <div className={'wheel_main'}>
                {!isSubmitting && !pair && goWheel && <Wheel options={options} goBack={hideWheel}/>}
            </div>
        </div>
    );
}