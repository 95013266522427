import React, {createContext, ReactElement, ReactNode, useEffect, useState} from 'react';
import {UserStruct} from "../../Helpers/DataTypes";

interface MyAppContextType {
    user: UserStruct | null,
    setNewUser: (u: UserStruct | null) => void
}

const STORAGE_KEY = "usess";
const STORAGE_TTL = 86400000; //1day

const getWithExpiry = (key: string): UserStruct | null => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const setWithExpiry = (key = STORAGE_KEY, value: object|null, ttl = STORAGE_TTL) => {
    // in case it's null, we can clear it
    if (!value) {
        localStorage.removeItem(key)
    }
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getDefault = () => {
    return getWithExpiry(STORAGE_KEY);
}

const defaultAppContext = {
    user: null,
    setNewUser: (u: UserStruct | null) => {
    }
} as MyAppContextType;

const AppContext = createContext<MyAppContextType>(defaultAppContext);

const AppContextProvider = ({children}: { children?: ReactNode | ReactElement }) => {
    // User default Name and Type
    const [user, setUser] = useState<UserStruct | null>(getDefault);

    const setNewUser = (u: UserStruct | null) => {
        setUser(u);
    }

    useEffect(() => {
        setWithExpiry(STORAGE_KEY, user, STORAGE_TTL);
    }, [user]);

    return (
        <AppContext.Provider value={{user, setNewUser}}>
            {children}
        </AppContext.Provider>
    )
}

export {AppContextProvider, AppContext};