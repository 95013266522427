import {ReactElement} from "react";
import {Button} from "react-bootstrap";
import {Modal as BModal} from "react-bootstrap";
interface ModalProps {
    title?: string,
    open: boolean,
    onClose: () => void,
    children: ReactElement
}
export const Modal = ({title, open, onClose, children}: ModalProps) => {
    return (
        <BModal show={open} onHide={onClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
            <BModal.Header closeButton>
                <BModal.Title>{title}</BModal.Title>
            </BModal.Header>
            <BModal.Body>
                {children}
            </BModal.Body>
            <BModal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </BModal.Footer>
        </BModal>
    )
}